<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;getList()" :clearable="false"></form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="clickDownload('LTV')">下 载</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item label="渠道:">
					<el-select v-model="queryForm.channel" size="mini" clearable>
						<el-option v-for="item in channels" :label="item.label" :value="item.value" :key="item.value"></el-option>
					</el-select>
				</form-item>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini" :clearable="false">
					</el-date-picker>
				</form-item>
				<form-item label="Country:">
					<el-input v-model.trim="queryForm.countryCode" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="Account ID:">
					<el-input v-model.trim="queryForm.accountId" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="Campaign ID:">
					<el-input v-model.trim="queryForm.campaignId" placeholder="请输入" size="mini"></el-input>
				</form-item>
			</template>
		</advanced-search-bar>
		<div class="d-flex flex-row flex-wrap">
			
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<ltv-table class="mt-3 w-100" :tableData="tableData"></ltv-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
	</el-card>
</template>

<script>
	import LtvTable from '@/components/campaign-ltv/ltv-table.vue'
	import config from './minixs/index.js'
	export default {
		mixins:[config],
		components: {
			LtvTable
		},
		data() {
			return {
				namespace: 'ltv-report'
			}
		}
	}
</script>

<style>
</style>
